<template lang="pug">
WinnerListing.pb-4(:items="items" :open-door="openDoor" @open="showDoor" @close="$emit('close',$event)" @howTo="$emit('howTo')" @back="goBack")
  template(v-slot:prizeList)
    slot(name="prizeList")
  slot
</template>
<script>
import { WinnerListing } from "@touridev/advent-calendar";
import { MarketingPromoContestPrizes } from "@/graphql/MarketingPromoContestPrizes.ts";
import EventBus from "@/event-bus";
import { createError } from "@/lib/components/error/createError";
import FatalError from "@/lib/components/error/FatalError";
export default {
  name: "AdventCalendarWinners",
  emits: ["open", "close", "howTo"],
  components: {
    WinnerListing,
  },
  props: {
    contest: Object,
    openDoor: Number,
    days: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      items: [],
      isLoading: true,
    };
  },
  watch: {
    async contest() {
      await this.loadItems();
    },
    isLoading(value) {
      if (!value) {
        EventBus.$emit("changeLoadingState", false);
        return;
      }
    },
  },
  methods: {
    goBack() {
      history?.back();
    },
    showDoor(number) {
      this.$emit("open", {
        number,
        isEarly: false,
      });
    },
    async loadItems() {
      try {
        if (!this.days?.length || !this.contest?.id) {
          throw createError();
        }
        const items = [];
        let i = 0;
        for (const day of this.days) {
          i++;
          const prizes = await this.$apollo
            .query({
              query:
                MarketingPromoContestPrizes.Queries
                  .MarketingPromoContestPrizesByDrawPrizeAt,
              variables: {
                marketingPromoContestId: this.contest.id,
                drawPrizeAt: day.toDate(),
              },
            })
            .then(
              (response) =>
                response?.data?.marketingPromoContestPrizesByDrawPrizeAt
            );
          items.push({
            day: i,
            values: [
              ...new Set(
                prizes.map((p) => {
                  const amount =
                    prizes.filter((pr) => pr.HtmlHeadline === p.HtmlHeadline)
                      ?.length || 1;
                  return `${amount > 1 ? `${amount}x ` : ""}${p.HtmlHeadline}`;
                })
              ),
            ],
          });
        }

        this.items = items.reverse();
      } catch (e) {
        if (e instanceof FatalError) throw e;
        this.$log.error(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
  async mounted() {
    EventBus.$emit("changeLoadingState", this.isLoading);
    await this.loadItems();
  },
};
</script>
<style scoped lang="scss"></style>
