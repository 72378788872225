<template lang="pug">
  #hotline-modal.modal-dialog(role="document")
    .modal-content.rounded-0
      .modal-header
        #hotline-modal-title.modal-title
          h2 Bestell-Hotline
        button.close(type="button" @click="$emit('close');" aria-label="Close")
          span(aria-hidden="true") &times;
      .modal-body
        p Alle Artikel können Sie auch ganz einfach per Telefon bestellen.
          br
          | Wir freuen uns auf Ihren Anruf.
        .h1.mb-0.hotline(:class="hotlineColorClass") {{hotline}}
        small.txt-td-grey-basic {{hotlineHours}}
        template(v-if="item")
          h3.mt-4 Artikel
          p
            b {{item.SystemItemId}}
            | &nbsp;- {{itemTitle}}
        call-me-back-form(:item="item")
</template>

<script>
import Helper from "@/lib/helper";
import callMeBackForm from "@/views/components/partials/forms/call-me-back-form";
import * as t from "@/store/main.json";

export default {
  name: "hotline-modal",
  props: {
    hotline: {
      type: String,
      default: t.main.hotlinePhone
    },
    hotlineColorClass: {
      type: String,
      default: "txt-tdays-lighter"
    },
    hotlineHours: {
      type: String,
      default: t.main.buisnessHourHotline
    },
    item: Object
  },
  components: {
    callMeBackForm
  },
  data() {
    return {
      Helper
    };
  },
  computed: {
    itemTitle() {
      return this.item.CustomAttributes?.Name2 || this.item.CustomAttributes?.Name1 || this.item.Name;
    }
  }
};
</script>

<style scoped>

</style>
