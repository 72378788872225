<template lang="pug">
  .option
    input.d-none(type="radio" :id="`option-${optionId}`" :data-value="value" :checked="selected")
    .row
      .col-12
        label.bordered.w-100(:for="`option-${optionId}`" :class="selected ? 'selected' : ''")
          .d-flex
            .col.icon.p-0(v-if="hasIconSlot")
              slot(name="icon")
            .col.icon.p-0(v-else)
              font-awesome-icon(icon="check-square" v-if="selected")
              font-awesome-icon(icon="square" v-else)
            .col.heading.p-0
              .pl-3
                slot(name="label")
    .row.description(v-if="hasDescriptionSlot && selected")
      .col-12
        .p-3
          slot(name="description")
</template>
<script>
const uuid = require("uuid");

export default {
  name: "AccordionSelectorOption",
  props:{
    selected:Boolean,
    value:[String,Number],
  },
  data(){
    return {
      optionId:null
    }
  },
  computed:{
    hasDescriptionSlot(){
      return !!this.$slots.description
    },
    hasIconSlot(){
      return !!this.$slots.icon
    }
  },
  created(){
    this.optionId = uuid.v4();
  }
}
</script>
<style scoped lang="scss">
@import '@/assets/styles/_colors.scss';
@import '@/assets/styles/buttons.scss';
.option{
  background-color:#fff;
}
label{
  line-height:50px;
  padding:0;
  margin:0;
  transition:all 0.4s;
  color:#fff;
  @include td-button-bg-effect($td-grey-light, $td-grey-light, $td-grey-basic);
  cursor:pointer;
  &.selected{
    @include td-button-bg-effect($tdays-lighter, $tdays-lighter, $tdays-light);
  }
}
.bordered{
  box-sizing:content-box!important;
  border-bottom:1px solid rgba(0,0,0,0.15);
}
.col.icon{
  text-align:center;
  font-size:21px;
  max-width:50px;
  flex:0 0 50px;
  background-color:rgba(0,0,0,0.15);
}
.col.heading{
  max-width:calc(100% - 50px);
  flex:0 0 calc(100% - 50px);
}
.description{
  overflow:hidden;
  max-height:0;
  animation:descriptionFlyIn 1s normal forwards;
}
@keyframes descriptionFlyIn {
  0% {max-height:0;}
  100% {max-height:300px;}
}
</style>