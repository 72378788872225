<template lang="pug">
#adventCalendar
  router-view(@submitEmail="submitEmail" @howTo="showHowToModal" @open="showDoor" @close="openDoor = undefined" v-bind="viewProps" v-if="contest")
    template(v-slot:prizeList)
      .prizes
        PrizeBox.tw-my-8(v-for="prize in prizes" v-bind="prize")
          template(v-slot:link="{url}")
            router-link.button.button-secondary.button-tdays-dark.my-4.text-decoration-none(:to="url" style="font-size:1.2rem") zum Angebot
    .mt-4.pt-4(v-if="hasStarted && showsCountdown")
      .text-center.h1 NÄCHSTE VERLOSUNG IN:
      .pb-4.mt-4.w-100.d-flex.justify-content-center
        flip-countdown(:units="['hours','minutes','seconds']" :deadline="nextDeadline" style="transform:scale(1.5)" background-color="#B19A65" color="#222" @end="onCountdownEnd")
  //AdventCalendar(:start-date="dateRange.start" :end-date="dateRange.end")
    template(v-slot:termsAndConditions) Hiermit stimme ich zu, die Datenschutzbestimmungen sowie Teilnahmebedingungen gelesen und akzeptiert zu haben. Als Gewinnspiel-Teilnehmer von touriDat willigen Sie der Nutzung Ihrer E-Mail-Adresse zur Zusendung von Produktempfehlungen per Mail ein, von denen Sie sich jederzeit über einen Link in der Mail abmelden können.
</template>

<script>
import { AdventCalendar, PrizeBox } from "@touridev/advent-calendar";
import { MarketingPromoContest } from "@/graphql/MarketingPromoContest.ts";
import config from "@/config/config";
import { MarketingPromoContestParticipants } from "@/graphql/MarketingPromoContestParticipants.ts";
import EventBus from "@/event-bus";
import moment from "moment";
import FlipCountdown from "@/views/components/partials/countdown/FlipCountdown.vue";
import { MarketingPromoContestPrizes } from "@/graphql/MarketingPromoContestPrizes.ts";
import { clamp } from "@/lib/helper/math";
import { Items } from "@/graphql/Items.ts";
import { getDisplayableHostImages, listImageUrls } from "@/lib/helper/host";
import XmasInfoModal from "@/views/static/adventCalendar/partials/XmasInfoModal.vue";
import { createError } from "@/lib/components/error/createError";

export default {
  name: "AdventCalendarView",
  components: {
    FlipCountdown,
    AdventCalendar,
    PrizeBox,
  },
  setup() {
    return {
      siteKey: config.external.google.recaptcha.siteKey,
      recaptchaScript: undefined,
    };
  },
  data() {
    return {
      contest: undefined,
      openDoor: undefined,
      prizes: [],
      showsCountdown: false,
      nextDeadline: undefined,
    };
  },
  metaInfo() {
    return {
      title: "touriDat Adventskalender 2023 | mehr als 300 Preise",
      meta: [
        {
          vmid: "description",
          name: "description",
          content:
            "GENIEßEN SIE MIT UNS GEMEINSAM DIE SCHÖNSTE ZEIT DES JAHRES | Lassen Sie sich jeden Tag aufs Neue von unseren Reisegewinnen verzaubern! Jetzt anmelden, gewinnen und den Reisegutschein ganz bequem einlösen!",
        },
        {
          vmid: "og:title",
          name: "og:title",
          content:
            "touriDat Adventskalender 2023 | mehr als 300 Preise | touriDat.com",
        },
        {
          vmid: "og:description",
          name: "og:description",
          content:
            "GENIEßEN SIE MIT UNS GEMEINSAM DIE SCHÖNSTE ZEIT DES JAHRES | Lassen Sie sich jeden Tag aufs Neue von unseren Reisegewinnen verzaubern! Jetzt anmelden, gewinnen und den Reisegutschein ganz bequem einlösen!",
        },
        {
          vmid: "keywords",
          name: "keywords",
          content:
            "adventskalender, gewinnspiel, weihnachten, weihnachtszeit, reisegutschein",
        },
        {
          vmid: "og:image",
          name: "og:image",
          content:
            "https://s3-cloud.td-cdn.de/Images/xmas-2023/xmas-2023-og-image.jpg",
        },
      ],
    };
  },
  watch: {
    async $route() {
      await this.updateDeadline();
    },
  },
  computed: {
    viewProps() {
      const props = {
        openDoor: this.openDoor,
      };
      if (this.$route.name === "AdventCalendar")
        return {
          ...props,
          prizeEnabledFrom: this.contest?.PrizeEnabledFrom
            ? moment(this.contest.PrizeEnabledFrom).toDate()
            : undefined,
          prizeEnabledTill: this.contest?.PrizeEnabledTill
            ? moment(this.contest.PrizeEnabledTill).toDate()
            : undefined,
          registrationTill: this.contest?.RegistrationTill
            ? moment(this.contest.RegistrationTill).toDate()
            : undefined,
          registrationFrom: this.contest?.RegistrationFrom
            ? moment(this.contest.RegistrationFrom).toDate()
            : undefined,
          alert: this.$alert,
          getDoorIsPast: (currentDay, openDoor) => {
            const currentDate = moment(new Date());
            const doorDate = this.getDateForDay(openDoor);
            return currentDate.isSameOrAfter(doorDate);
          },
        };
      const days = [];
      for (let i = 1; i < this.currentDay + 1; i++) {
        days.push(this.getDateForDay(i));
      }
      return {
        ...props,
        days,
        contest: this.contest,
      };
    },
    hasStarted() {
      const now = new Date();
      return moment(now).isSameOrAfter(this.dateRange.start);
    },
    referrerId() {
      const query = this.$route?.query;
      if (!query || !Object.keys(query)) return;
      const data = {};
      Object.keys(query).forEach((key) => {
        data[key.toLowerCase()] = query[key];
      });
      return data?.referrerid;
    },
    /*
    nextDeadline() {
      const today = this.getDateForDay(this.currentDay);
      const deadline = moment(new Date()).isSameOrBefore(today)
        ? this.getDateForDay(this.currentDay)
        : this.getDateForDay(this.currentDay + 1);
      return deadline.format("YYYY-MM-DD HH:mm:ss");
    },

     */
    currentDay() {
      const now = new Date();
      const s = moment(this.dateRange.start)
        .set("hour", 0)
        .set("minute", 0)
        .set("seconds", 0);
      return moment(now).diff(s, "days") + 1;
    },
    dateRange() {
      return {
        start: this.contest?.PrizeEnabledFrom
          ? moment(this.contest.PrizeEnabledFrom).toDate()
          : undefined,
        end: this.contest?.PrizeEnabledFrom
          ? moment(this.contest.PrizeEnabledTill).toDate()
          : undefined,
      };
    },
  },
  methods: {
    async updateDeadline() {
      const deadline = await this.getNextDeadline();
      if (!deadline) return;
      this.nextDeadline = moment(deadline).format("YYYY-MM-DD HH:mm:ss");
      this.showsCountdown = true;
    },
    async onCountdownEnd() {
      this.showsCountdown = false;
      await this.updateDeadline();
    },
    async getNextDeadline() {
      if (!this.contest?.id) return;
      return await this.$apollo
        .query({
          query:
            MarketingPromoContestPrizes.Queries
              .NextDrawnMarketingPromoContestPrize,
          variables: {
            marketingPromoContestId: this.contest?.id,
          },
        })
        .then(
          (response) =>
            response?.data?.nextDrawnMarketingPromoContestPrize?.DrawPrizeAt
        );
    },
    async loadPrizeTicket(prizeId) {
      return await this.$apollo
        .query({
          query:
            MarketingPromoContestParticipants.Queries
              .MarketingPromoContestPrizeTicket,
          variables: {
            marketingPromoContestPrizeId: prizeId,
          },
        })
        .then((response) => response?.data?.marketingPromoContestPrizeTicket);
    },
    getGroupedPrizes(prizes) {
      prizes = prizes.sort((a, b) => {
        const aId = a?.ItemId ? parseInt(a.ItemId) : 0;
        const bId = b?.ItemId ? parseInt(b.ItemId) : 0;
        return bId - aId;
      });
      const groupedPrizes = [];
      for (const prize of prizes) {
        const existingPrize = groupedPrizes.find(
          (p) => p.ItemId === prize.ItemId
        );
        if (existingPrize) {
          existingPrize.amount++;
          if (prize.ticket) {
            existingPrize.winners.push(prize.ticket?.Ticket);
          }
          continue;
        }
        groupedPrizes.push({
          ...prize,
          amount: 1,
          winners: prize.ticket ? [prize.ticket?.Ticket] : [],
        });
      }
      return groupedPrizes.map((prize) => {
        return {
          headline: prize?.IsDiscountOnlyPrize
            ? `${prize.Value}€ Wertgutschein`
            : prize?.HtmlHeadline,
          subline: prize?.HtmlSubline,
          description: prize.IsDiscountOnlyPrize
            ? "Neben den traumhaften Reisen von touriDat und den diesjährigen Luxusreisen haben Sie täglich die Zusatz-Chance einen von zehn 25-EUR-Wertgutscheinen zu gewinnen und Ihre nächste Reise bei touriDat noch günstiger zu schnappen. An Nikolaus warten nicht nur zwei ganz besondere Reiseerlebnisse auf Sie sondern zusätzlich vier touriDat-Auswahlgutscheine sowie 15 Wertgutscheine à 25,00 EUR!. Die Wertgutscheine sind frei übertragbar, nicht von der rabattierung durch Wertgutscheine betroffen, an einen Mindestbestellwert gebunden und können bis zum 31.12.2024 eingelöst werden"
            : prize?.HtmlDescription,
          url:
            prize?.item?.Host?.Url && prize?.item?.id
              ? `/gastgeber/${prize.item.Host.Url}#${prize.item.id}`
              : undefined,
          images: prize?.IsDiscountOnlyPrize
            ? [
                "https://td-cloud.s3.eu-central-1.amazonaws.com/Images/xmas-2022/voucher1.jpg",
                "https://td-cloud.s3.eu-central-1.amazonaws.com/Images/xmas-2022/voucher2.jpg",
                "https://td-cloud.s3.eu-central-1.amazonaws.com/Images/xmas-2022/voucher3.jpg",
              ]
            : prize?.ImageUrl
            ? [prize?.ImageUrl]
            : prize?.item?.Host?.Images?.length
            ? listImageUrls(
                getDisplayableHostImages(prize.item.Host).slice(0, 3),
                "medium",
                "small",
                "small"
              )
            : [],
          value: prize?.item?.RegularPrice,
          amount: prize?.amount || 1,
          winners: prize?.winners || [],
        };
      });
    },
    showHowToModal() {
      this.$modal.show(
        XmasInfoModal,
        {},
        {
          width: "500px",
          height: "auto",
          classes: ["xmas-modal", "xmas-info-modal", "rounded-0"],
        }
      );
    },
    getDateForDay(day) {
      if (!day) return;
      return moment(this.dateRange.start)
        .add(clamp(day - 1, 0), "days")
        .set("hour", 9)
        .set("minute", 0)
        .set("second", 0);
    },
    async showDoor(door) {
      if (!this.contest?.id) return;
      this.openDoor = undefined;
      const { number } = door;
      if (door.isEarly) {
        return await this.$alert(
          `Diese Tür kann erst ab dem ${this.getDateForDay(number).format(
            "DD.MM.YYYY"
          )} geöffnet werden`,
          "Sie sind zu früh dran..."
        );
      }
      try {
        EventBus.$emit("changeLoadingState", true);

        const date = this.getDateForDay(number);
        const prizes = await this.$apollo
          .query({
            query:
              MarketingPromoContestPrizes.Queries
                .MarketingPromoContestPrizesByDrawPrizeAt,
            variables: {
              drawPrizeAt: date.format("YYYY-MM-DD HH:mm:ss"),
              marketingPromoContestId: this.contest.id,
            },
          })
          .then(async (response) => {
            const p =
              response?.data?.marketingPromoContestPrizesByDrawPrizeAt || [];
            if (!p.length) return [];
            const results = [];
            for (const result of p) {
              if (result.ItemId) {
                const item = await this.$apollo
                  .query({
                    query: Items.Queries.Item,
                    variables: {
                      id: result.ItemId,
                    },
                  })
                  .then((response) => response?.data?.item);
                result.item = item;
              }

              const ticket = await this.loadPrizeTicket(result.id);
              if (ticket) result.ticket = ticket;

              results.push(result);
            }

            return results;
          });
        if (!prizes.length) {
          return;
        }

        this.prizes = this.getGroupedPrizes(prizes);
        this.openDoor = number;
      } finally {
        EventBus.$emit("changeLoadingState", false);
      }
    },
    async createMarketingPromoContestParticipant(token, email) {
      return await this.$apollo
        .mutate({
          mutation:
            MarketingPromoContestParticipants.Mutations
              .CreateMarketingPromoContestParticipant,
          variables: {
            marketingPromoContestId: this.contest.id,
            email: email,
            token,
            marketingPromoParticipantReferrerGuid:
              this.$session.get("contestReferrerId") || this.referrerId,
            signUpThroughSource: this.$route?.query?.source,
          },
        })
        .then((response) => {
          const result = response?.data?.createMarketingPromoContestParticipant;
          if (result?.isSuccess === false)
            throw new Error(
              result?.Message || "Es ist ein unbekannter Fehler aufgetreten"
            );
          return result;
        });
    },
    async submitEmail(data) {
      const { email } = data;
      if (!this.recaptchaCallback) return;
      EventBus.$emit("changeLoadingState", true);
      this.recaptchaCallback(async (token) => {
        try {
          const r = await this.createMarketingPromoContestParticipant(
            token,
            email
          );
          if (r.Message) this.$alert(r.Message);
        } catch (e) {
          this.$log.error(e);
          this.$alert(e.message);
        } finally {
          EventBus.$emit("changeLoadingState", false);
        }
      });
    },
    async loadContest() {
      EventBus.$emit("changeLoadingState", true);
      this.contest = await this.$apollo
        .query({
          query: MarketingPromoContest.Queries.MarketingPromoContest,
          variables: {
            marketingPromoContestId: 9,
          },
        })
        .then((response) => {
          if (!response?.data?.marketingPromoContest)
            throw new Error(`response is empty`);
          return response?.data?.marketingPromoContest;
        });
      EventBus.$emit("changeLoadingState", false);
    },
    teardown() {
      if (this.recaptchaScript) {
        this.recaptchaScript.parentNode.removeChild(this.recaptchaScript);
        this.recaptchaScript = undefined;
      }

      const badgeElement = document.querySelector(".grecaptcha-badge");
      if (badgeElement) {
        const el = badgeElement.parentNode;
        if (el) el.parentNode.removeChild(el);
      }

      if (document.documentElement.dataset.view === "advent-calendar")
        document.documentElement.removeAttribute("data-view");
    },
  },
  async mounted() {
    document.documentElement.dataset.view = "advent-calendar";
    EventBus.$emit("changeLoadingState", true);
    if (this.referrerId)
      this.$session.set("contestReferrerId", this.referrerId);
    try {
      await this.loadContest();
      await this.updateDeadline();
    } catch (e) {
      if (this.$touriConfig.runMode === "production") throw createError();
      return;
    } finally {
      EventBus.$emit("changeLoadingState", false);
    }

    try {
      if (this.recaptchaScript)
        this.recaptchaScript.parentNode.removeChild(this.recaptchaScript);
      this.recaptchaScript = document.createElement("script");
      this.recaptchaScript.src = `https://www.google.com/recaptcha/api.js?render=${this.siteKey}`;
      document.head.appendChild(this.recaptchaScript);
      this.recaptchaScript.onload = () => {
        this.recaptchaCallback = (cb) => {
          const grecaptcha = window["grecaptcha"];
          if (!grecaptcha) return;
          grecaptcha.ready(() => {
            grecaptcha
              .execute(this.siteKey, { action: "submit" })
              .then(function (token) {
                if (!token) return;
                cb(token);
              });
          });
        };
      };
    } catch (e) {
      this.$log.error(e);
    } finally {
      EventBus.$emit("changeLoadingState", false);
    }
  },
  destroyed() {
    this.teardown();
  },
  unmounted() {
    this.teardown();
  },
};
</script>
<style lang="scss">
[data-view="advent-calendar"] {
  scroll-behavior: smooth;
  #mainNav,
  .widgets .shopping-basket-button {
    display: none !important;
  }
}
#adventCalendar {
  @import "@touridev/advent-calendar/style";
}
</style>
