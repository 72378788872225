import Vue from "vue";
import VueRouter from "vue-router";
import Meta from "vue-meta";
// Cache implementation
import { initializeSession } from "@/lib/helper/router";
import { useSeasonalRoutes } from "@/router/seasonal";

Vue.use(VueRouter);
Vue.use(Meta);

let routes = [
  {
    path: "/",
    name: "Home",
    props: true,
    component: () => import(/* webpackChunkName: "Home" */ "../views/home/Home")
  },
  {
    path: "/error",
    name: "Error",
    props: true,
    component: () => import(/* webpackChunkName: "Error" */ "../views/error/Error")
  },
  {
    path: "/tipps",
    alias: ["/deluxe", "/golf"],
    name: "BrandCategory",
    props: true,
    component: () => import(/* webpackChunkName: "Category" */ "../views/category/partials/BrandCategory")
  },
  {
    path: "/kategorien/deluxe",
    name: "DeluxeCategory",
    props: true,
    component: () => import(/* webpackChunkName: "DeluxeCategory" */ "../views/category/DeluxeCategory")
  },
  {
    path: "/kategorien/tipps",
    name: "TippsCategory",
    component: () => import(/* webpackChunkName: "TippsCategory" */ "../views/category/TippsCategory")
  },
  {
    path: "/kategorien/themen/auswahlgutscheine",
    name: "VoucherCategory",
    props: true,
    component: () => import(/* webpackChunkName: "VoucherCategory" */ "../views/category/VoucherCategory")
  },
  {
    path: "/kategorien/themen/wertgutscheine",
    name: "DiscountVoucherCategory",
    component: () => import(/* webpackChunkName: "VoucherCategory" */ "../views/category/DiscountVoucherCategory")
  },
  {
    path: "/kategorien/(.*)",
    name: "Category",
    props: true,
    component: () => import(/* webpackChunkName: "Category" */ "../views/category/Category")
  },
  {
    path: "/gastgeber/:name",
    name: "Host",
    props: true,
    component: () => import(/* webpackChunkName: "Host" */ "../views/host/HostView")
  },
  {
    path: "/kasse",
    name: "Checkout",
    props: true,
    component: () => import(/* webpackChunkName: "Checkout" */ "../views/checkout/Checkout")
  },
  {
    path: "/kauf/amazon",
    name: "AmazonOrderOverview",
    props: true,
    component: () => import(/* webpackChunkName: "OrderConfirmation" */ "../views/orders/amazon/AmazonOrderOverview")
  },
  {
    path: "/kauf/bestaetigung",
    name: "OrderConfirmation",
    props: true,
    component: () => import(/* webpackChunkName: "OrderConfirmation" */ "../views/orders/OrderConfirmation")
  },
  {
    path: "/kauf/klarna/bestaetigung",
    name: "KlarnaOrderConfirmation",
    props: true,
    component: () => import(/* webpackChunkName: "OrderConfirmation" */ "../views/orders/OrderConfirmation")
  },
  {
    path: "/emails/:orderActionGuid",
    name: "EmailDetails",
    props: true,
    component: () => import(/* webpackChunkName: "EmailDetails" */ "../views/emails/EmailDetails")
  },
  {
    path: "/passwort-zuruecksetzen",
    name: "ResetUserPassword",
    props: true,
    component: () => import(/* webpackChunkName: "ResetUserPassword" */ "../views/userPassword/ResetUserPassword")
  },
  {
    path: "/passwort-aktualisieren",
    name: "UpdateUserPassword",
    props: true,
    component: () => import(/* webpackChunkName: "UpdateUserPassword" */ "../views/userPassword/UpdateUserPassword")
  },
  {
    path: "/registration",
    name: "UserCreation",
    props: true,
    component: () => import(/* webpackChunkName: "UserCreation" */ "../views/user/creation/UserCreation")
  },
  {
    path: "/benutzer-verifizieren",
    name: "UserCreationConfirmation",
    props: true,
    component: () => import(/* webpackChunkName: "UserCreationConfirmation" */ "../views/user/UserCreationConfirmation")
  },
  {
    path: "/benutzerkonto",
    alias: ["/account"],
    name: "Account",
    props: true,
    component: () => import(/* webpackChunkName: "Account" */ "../views/user/Account"),
    children: [
      {
        path: "",
        redirect: "bestellungen"
      },
      {
        path: "bestellungen",
        name: "UserOrders",
        component: () => import(/* webpackChunkName: "UserOrders" */ "../views/user/orders/UserOrders.vue")
      },
      {
        path: "bestellungen/:orderId",
        name: "UserOrderDetails",
        props: true,
        alias: ["orders/:orderId"],
        component: () => import(/* webpackChunkName: "UserOrderDetails" */ "../views/user/orders/UserOrderDetails.vue")
      },
      {
        path: "favoriten",
        name: "UserHostFavorites",
        component: () => import(/* webpackChunkName: "UserHostFavorites" */ "../views/user/userHostFavorites/UserHostFavorites.vue")
      },
      {
        path: "adressen",
        name: "UserAddresses",
        component: () => import(/* webpackChunkName: "UserAddresses" */ "../views/user/addresses/UserAddresses.vue")
      }
    ]
  },
  {
    path: "/account/orders/:orderId",
    name: "UserOrderDetails",
    props: true,
    component: () => import(/* webpackChunkName: "UserOrderDetails" */ "../views/user/orders/UserOrderDetails")
  },
  {
    path: "/auswahlgutscheine",
    name: "SelectionVoucher",
    props: true,
    component: () => import(/* webpackChunkName: "SelectionVoucher" */ "../views/selectionVoucher/SelectionVoucher")
  },
  {
    path: "/auswahlgutscheine/einloesen",
    name: "RedeemSelectionVoucher",
    props: true,
    component: () => import(/* webpackChunkName: "RedeemSelectionVoucher" */ "../views/selectionVoucher/RedeemSelectionVoucher")
  },
  {
    path: "/suche",
    alias: ["/suche/erweitert"],
    name: "Search",
    component: () => import(/* webpackChunkName: "Search" */ "../views/search/Search.vue")
  },
  {
    path: "/karte",
    name: "Map",
    props: true,
    component: () => import(/* webpackChunkName: "Map" */ "../views/map/Map")
  },
  {
    path: "/faq",
    name: "static-faq",
    props: true,
    component: () => import(/* webpackChunkName: "faq" */ "../views/static/faq")
  },
  {
    path: "/impressum",
    name: "static-imprint",
    props: true,
    component: () => import(/* webpackChunkName: "imprint" */ "../views/static/imprint")
  },
  {
    path: "/widerrufsrecht",
    name: "static-cancellation-right",
    props: true,
    component: () => import(/* webpackChunkName: "cancellation-right" */ "../views/static/cancellation-right")
  },
  {
    path: "/agb",
    name: "static-tos",
    props: true,
    component: () => import(/* webpackChunkName: "tos" */ "../views/static/tos")
  },
  {
    path: "/touridays",
    name: "static-buyer-protection",
    props: true,
    component: () => import(/* webpackChunkName: "buyer-protection" */ "../views/static/buyer-protection")
  },
  {
    path: "/werde-ein-gastgeber",
    name: "static-become-a-host",
    props: true,
    component: () => import(/* webpackChunkName: "become-a-host" */ "../views/static/become-a-host")
  },
  /*
  {
    path: "/geschenkbox",
    name: "present-box",
    props: true,
    component: () => import(/ webpackChunkName: "present-box" / "../views/static/present-box")
  },
  */
  {
    path: "/versand",
    name: "shipping-methods",
    props: true,
    component: () => import(/* webpackChunkName: "shipping-methods" */ "../views/static/shipping-methods")
  },
  {
    path: "/datenschutz",
    name: "data-protection",
    props: true,
    component: () => import(/* webpackChunkName: "data-protection" */ "../views/static/data-protection")
  },
  {
    path: "/kontakt",
    name: "static-contact",
    props: true,
    component: () => import(/* webpackChunkName: "contact" */ "../views/static/contact")
  },
  {
    path: "/newsletter",
    name: "static-newsletter",
    props: true,
    component: () => import(/* webpackChunkName: "newsletter" */ "../views/static/newsletter")
  },
  {
    path: "/partner",
    name: "partner-network",
    props: true,
    component: () => import(/* webpackChunkName: "/partner-network" */ "../views/static/partner-network")
  },
  {
    path: "/karriere",
    name: "static-career",
    props: true,
    component: () => import(/* webpackChunkName: "career" */ "../views/static/career")
  },
  {
    path: "/sponsoring",
    name: "static-sponsoring",
    props: true,
    component: () => import(/* webpackChunkName: "sponsoring" */ "../views/static/sponsoring")
  },
  {
    path: "/app",
    name: "static-td-app",
    props: true,
    component: () => import(/* webpackChunkName: "td-app" */ "../views/static/td-app")
  },
  {
    path: "/winner-2018",
    name: "static-winner-2018",
    props: true,
    component: () => import(/* webpackChunkName: "winner-2018" */ "../views/static/winner-2018")
  },
  {
    path: "/feedback",
    name: "Feedback",
    component: () => import(/* webpackChunkName: "Feedback" */ "../views/feedback/Feedback")
  },
  {
    path: "/auswahlgutschein/ao-hotels",
    name: "ao-hotels",
    props: true,
    component: () => import(/* webpackChunkName: "ao-voucher" */ "../views/static/ao-voucher")
  },
  {
    path: "/teilnahmebedingungen",
    alias: ["/promo/teilnahmebedingungen", "/contest-conditions"],
    name: "ContestConditionsSite",
    component: () => import(/* webpackChunkName: "AdventCalendar" */ "../views/static/contest-conditions-site.vue")
  }
];

const router = new VueRouter({
  routes: [
    ...routes,
    ...useSeasonalRoutes(),
    {
      path: "/:catchAll(.*)",
      name: "NotFound",
      component: () => import(/* webpackChunkName: "NotFoundError" */ "../views/error/NotFoundError.vue")
    }
  ],
  mode: "history"
});

/*
const httpLink = createHttpLink({
    // You should use an absolute URL here
    uri: cfg.graphQl.uri,
});

const cache = new InMemoryCache();

const apolloClient = new ApolloClient({
    link: httpLink,
    cache,
    defaultOptions: {
        fetchPolicy: "no-cache",
    },
});

 */

router.beforeEach(async (to, from, next) => {
  try {
    initializeSession(to.query, router.app.$session);
    return next();
  } catch (e) {
    router.app.$log.error(e);
    return next();
  }
});

// eslint-disable-next-line no-unused-vars
router.afterEach(async (to, from, next) => {
  window.prerenderReady = true;
});
export default router;
