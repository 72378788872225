<template lang="pug">
.shipping-method-selector
  .h2.txt-td-grey-basic Versandart
  AccordionSelector(@change="selectShippingType(parseInt($event))")
    AccordionSelectorOption(v-for="option in shippingTypeOptions" :selected="option.value === shippingTypeId" :value="option.value")
      template(v-slot:label) {{option.label}}
      template(v-slot:icon)
        font-awesome-icon(:icon="option.icon")
      template(v-slot:description) {{option.description}}
</template>
<script>
import AccordionSelector from "@/views/components/accordionSelector/AccordionSelector.vue";
import AccordionSelectorOption from "@/views/components/accordionSelector/partials/AccordionSelectorOption.vue";
import shippingTypeOptions from "@/lib/shippingTypeOptions";
import { ShippingTypeEnum } from "@/utils/ShippingTypeEnum.ts";
import { sendAddShippingInfo } from "@/lib/helper/gtm";

export default {
  name: "ShippingMethodSelector",
  components: { AccordionSelectorOption, AccordionSelector },
  setup() {
    return { shippingTypeOptions };
  },
  computed: {
    shippingTypeId() {
      return this.$shoppingBasket.shipping?.id || ShippingTypeEnum.Email;
    },
  },
  methods: {
    getShippingTypeName(typeId) {
      switch (typeId) {
        default:
        case ShippingTypeEnum.Email:
          return "Email";
        case ShippingTypeEnum.Letter:
          return "Letter";
        case ShippingTypeEnum.Box:
          return "Box";
      }
    },
    selectShippingType(value) {
      const type = this.shippingTypeOptions.find(
        (shippingType) => shippingType.value === value
      );
      if (!type?.value) return;
      sendAddShippingInfo(
        this.$shoppingBasket.uniqueItems,
        type.price,
        this.getShippingTypeName(type.value)
      );
      this.$shoppingBasket.setShipping({
        id: type.value,
        value: type.price,
      });
    },
  },
};
</script>
<style scoped lang="scss"></style>
