<template lang="pug">
.flip-countdown
  .d-flex
    .time(v-for="unit in units" :class="[unit,{flip:changingUnits.includes(unit)}]" :data-unit="unit" :style="`--countdown-color:${color};--countdown-background:${backgroundColor};`")
      .value(:data-value="pad(cd[unit])") {{pad(cd[unit])}}
      .label {{labels[unit]}}
</template>

<script>
import { clamp } from "@/lib/helper/math";

let interval;

const pad = (val) => {
  if (!val) return "00";
  if (val < 10) return `0${val}`;
  return val.toString();
};

export default {
  name: "FlipCountdown",
  emits: ["end"],
  props: {
    deadline: {
      type: String,
      required: true,
    },
    units: {
      type: Array,
      default() {
        return ["days", "hours", "minutes", "seconds"];
      },
    },
    labels: {
      type: Object,
      default() {
        return {
          days: "Tage",
          hours: "Stunden",
          minutes: "Minuten",
          seconds: "Sekunden",
        };
      },
    },
    color: {
      type: String,
      default: "#777",
    },
    backgroundColor: {
      type: String,
      default: "#fff",
    },
  },
  data() {
    return {
      remainingMilliseconds: 0,
      changingUnits: [],
    };
  },
  watch: {
    remainingMilliseconds(value) {
      if (!value) this.$emit("end");
    },
    cd(newVal, oldVal) {
      this.changingUnits = [];
      Object.keys(newVal).forEach((unit) => {
        if (oldVal[unit] !== newVal[unit]) this.changingUnits.push(unit);
      });
    },
  },
  computed: {
    cd() {
      let delta = Math.abs(this.remainingMilliseconds) / 1000;

      const days = Math.floor(delta / 86400);
      delta -= days * 86400;

      const hours = Math.floor(delta / 3600) % 24;
      delta -= hours * 3600;

      const minutes = Math.floor(delta / 60) % 60;
      delta -= minutes * 60;

      const seconds = Math.floor(delta % 60);
      return { days, hours, minutes, seconds };
    },
  },
  methods: {
    pad,
    calculateRemainingMilliseconds() {
      const deadlineTimestamp = new Date(
        this.deadline.replace(/-/g, "/")
      ).getTime();
      this.remainingMilliseconds = clamp(deadlineTimestamp - Date.now(), 0);
    },
    start() {
      interval = setInterval(this.calculateRemainingMilliseconds, 1000);
    },
  },
  created() {
    this.calculateRemainingMilliseconds();
  },
  mounted() {
    this.start();
  },
  destroyed() {
    clearInterval(interval);
  },
  unmounted() {
    clearInterval(interval);
  },
};
</script>

<style scoped lang="scss">
.flip-countdown {
  &,
  & * {
    cursor: default;
    user-select: none;
  }
  .time {
    width: 60px;
    position: relative;
    aspect-ratio: 1.4 / 1;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin: 0 2px;

    &.flip {
      .value {
        &:before {
          animation: flipClock 1s 0.5s linear infinite normal forwards;
        }
        &:after {
          animation: flipShadow 1s 0.5s linear infinite normal forwards;
        }
      }
    }

    .label {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 100%);
      font-size: 75%;
    }
    .value {
      position: relative;
      width: 100%;
      height: 100%;
      font-size: 27px;
      font-weight: bold;
      perspective: 150px;
      background-color: var(--countdown-background, #fff);
      color: var(--countdown-color, #777);

      &:after,
      &:before {
        background-color: inherit;
        color: currentColor;
        content: attr(data-value);
        position: absolute;
        left: 0;
        width: 100%;
        height: 50%;
        overflow: hidden;
      }

      &:before {
        top: 0;
        transform-origin: center bottom;
        z-index: 1;
        height: calc(50% - 1px);

        @keyframes flipClock {
          0%,
          50% {
            transform: rotateX(0deg);
            opacity: 1;
          }
          100% {
            transform: rotateX(-180deg);
            opacity: 0;
          }
        }
      }

      &:after {
        bottom: 0;
        line-height: 0;
        z-index: 2;

        @keyframes flipShadow {
          0%,
          50%,
          100% {
            box-shadow: 0 0 5px rgba(0, 0, 0, 0) inset;
          }
          66.66% {
            box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15) inset;
          }
        }
      }
    }
  }
}
</style>
