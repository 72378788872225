<template lang="pug">
  ul(:data-level="level")
    li(:data-level="level" v-for="node in nodes")
      router-link.label(v-if="node.Url" :to="getRoute(node.Url)") {{node.Name}}
      TreeMenu(:nodes="node.Children" :level="level + 1" :base-path="basePath" :ref-type="refType")
</template>

<script>

export default {
  name: "TreeMenu",
  props: {
    nodes: Array,
    level: Number,
    refType: String,
    basePath: String
  },
  methods: {
    getRoute(path) {
      const route = { path: this.getFullPath(path) };
      if (this.refType) route.query = { ref: this.refType };
      return route;
    },
    getFullPath(path) {
      return this.basePath + path;
    }
  }
};
</script>

<style scoped></style>
