<template lang="pug">
  .category-navigation.mobile.w-100.position-relative
    .text-right
      button.close.my-3(@click="$emit('close')")
        font-awesome-icon(icon="times")
    SearchBox.my-5(v-model="searchTerm" @submit="search")
    ul
      li.static.d-block(:class="{'active':$route.name === 'Home'}")
        router-link.label(:to="{path:'/'}") Startseite
      li.sub-category.d-block(v-for="navItem in categories" :class="{'active':$route.path.indexOf(`/kategorien/${navItem.path}`) !== -1}")
        template(v-if="navItem.Children?.length > 0")
          a.label.branches(@click="toggleBranch")
            | {{navItem.Name}}
            i.plus
              font-awesome-icon(icon="plus")
            i.minus
              font-awesome-icon(icon="minus")
        template(v-else)
          a.label(@click="toggleBranch")
            | {{navItem.Name}}
        .branch
          router-link.label.category(:to="{path:`/kategorien/${navItem.Url}`,query:{ref:'nav_tree'}}") Alle Anzeigen
          TreeMenuCollapsible(@toggleBranch="toggleBranch" :nodes="navItem.Children" :level="0" ref-type="nav_tree" :base-path="`/${baseRoutePath}/`")
</template>
<script>
import TreeMenuCollapsible from "@/views/components/navbar/partials/TreeMenuCollapsible";
import {Categories} from "@/graphql/Categories.ts";
import SearchBox from "@/views/components/search/SearchBox";

export default {
  name: "NavbarMobile",
  components: {
    TreeMenuCollapsible,
    SearchBox
  },
  props:{
    baseRouteName:String
  },
  data(){
    return {
      currentBranch:null,
      searchTerm:'',
      categories:[],
    }
  },
  methods:{
    loadNavbarCategories() {
      try {
        this.$apollo.query({
          query: Categories.Queries.NavbarCategories,
        })
            .then(({data}) => {
              if (!data || !data.navbarCategories) {
                return;
              }
              this.categories = data.navbarCategories;
            })
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    toggleBranch(e){
      const branch = e.target.parentNode;
      if(branch.classList.contains('open')){
        branch.classList.remove('open')
        return;
      }
      branch.classList.add('open')
    },
    search(term){
      document.body.classList.remove('no-scroll');
      this.$router.push({
        name:'Search',
        query:{
          q:encodeURIComponent(term)
        }
      });
    }
  },
  computed:{
    baseRoutePath(){
      if(this.baseRouteName === 'Map')return 'karte';
      return 'kategorien';
    }
  },
  mounted(){
    this.loadNavbarCategories();
  }
}
</script>
<style scoped lang="scss">
:global(#mno){
  overflow:hidden;
  height:100vh;
  transition:height 0.5s;
}
:global(#mno.collapsed){
  height:0;
}
.category-navigation.mobile{
  max-height:100%;
  overflow:hidden;
  overflow-y:auto;
  .label{
    cursor:pointer;
  }
}
button.close{
  svg{
    pointer-events:none;
  }
}
</style>
