import gql from "graphql-tag";

export const MarketingPromoContest = {
  Queries: {
    MarketingPromoContest: gql`
      query MarketingPromoContest($marketingPromoContestId: ID) {
        marketingPromoContest(id: $marketingPromoContestId) {
          id
          RegistrationFrom
          RegistrationTill
          PrizeEnabledFrom
          PrizeEnabledTill
        }
      }
    `,
  },
};
