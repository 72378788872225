export const clamp = (value, min, max) => {
  if (value < min) return min;
  if (max && value > max) return max;
  return value;
};

export const sum = (...args) =>
  [...args].reduce((a, b) => parseFloat(a) + parseFloat(b), 0);

export const getRandomIntInRange = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const calculateDiscount = (item, hasDiscount = false) => {
  if (
    !item.CurrentPrice ||
    !item.RegularPrice ||
    (hasDiscount && !item.DiscountedPrice)
  )
    return;
  let currentPrice = item.CurrentPrice;
  // if the item has discount, DiscountedPrice will be used instead of CurrentPrice
  if (hasDiscount) currentPrice = item.DiscountedPrice;
  return ((item.RegularPrice - currentPrice) / item.RegularPrice) * 100;
};

export const parseNumber = (numericString) => {
  if (typeof numericString === "number") return numericString;
  if (!numericString || !isNumeric(numericString)) return 0;
  if (numericString.includes(".") || numericString.includes(","))
    return parseFloat(numericString);
  return parseInt(numericString);
};

export const radiusToSpan = (radius) => {
  return radius / 111;
};

export const isNumeric = (num) =>
  (typeof num === "number" || (typeof num === "string" && num.trim() !== "")) &&
  !isNaN(num);
