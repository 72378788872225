<template lang="pug">
.responsive-host-gallery
  SmallHostGallery.d-block.d-sm-none(:images="imageUrls")
  BigHostGallery.d-none.d-sm-flex(:images="images")
</template>
<script>
import SmallHostGallery from "@/views/components/host/gallery/SmallHostGallery";
import BigHostGallery from "@/views/components/host/gallery/BigHostGallery";
export default {
  name: "ResponsiveHostGallery",
  components: {SmallHostGallery,BigHostGallery},
  props:{
    images:{
      type:Array,
    }
  },
  computed:{
    imageUrls(){
      if(!this.images) return [];
      return this.images.map((image) => image.RemoteURL);
    }
  }
};
</script>
<style scoped lang="scss">

</style>
