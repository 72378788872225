<template lang="pug">
  .shopping-basket-items
    .h2.txt-td-grey-basic Artikel
    //UserCartInfo(v-if="showsUserInfo")
    .p-3.bg-td-grey-lighter
      ShoppingBasketItem.itm(v-for="item in items" v-bind="item" :shows-voucher-details="itemHasVoucherDetails(item)" :link-to-item="!itemIsDiscountVoucher(item)" :max-quantity="getMaxQuantity(item)" @remove="$emit('removeAll',item)" @changeQuantity="changeItemQuantity(item,$event)" @exceedQuantity="showExceedQuantityMessage()" :readonly="readonly")
    .small.font-weight-bold(v-if="containsDiscountVoucher || isInvalidForLiveShoppingDiscount") Hinweis:&nbsp
      template(v-if="containsDiscountVoucher") Wertgutscheine sind von der Rabattierung ausgeschlossen.
      template(v-if="isInvalidForLiveShoppingDiscount") Der aktuelle Rabatt ist nicht auf Artikel zum Deal-Preis anwendbar.
</template>
<script>
import ShoppingBasketItem from "@/views/components/shoppingBasket/partials/ShoppingBasketItem.vue";
import { createCheckoutBasket } from "@/lib/helper/items";
import { Items } from "@/graphql/Items.ts";
import UserCartInfo from "@/views/components/userAccount/UserCartInfo.vue";

export default {
  name: "ShoppingBasketItemList",
  components: { UserCartInfo, ShoppingBasketItem },
  props: {
    readonly: Boolean,
    showsUserInfo: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      availabilities: []
    };
  },
  computed: {
    items() {
      return this.$shoppingBasket.uniqueItems;
    },
    isInvalidForLiveShoppingDiscount() {
      const discounts = this.$shoppingBasket?.discounts || [];
      const [discount] = discounts;
      console.log(discount);
      return discount?.appliesToDiscountedItems === false;
    },
    containsDiscountVoucher() {
      return !!this.items.find((item) => [5, 9].includes(item.WebTemplateId));
    }
  },
  async mounted() {
    await this.updateAvailabilities();
  },
  methods: {
    itemIsDiscountVoucher(item) {
      const webTemplateId = item?.WebTemplateId || 1;
      return [5, 9].includes(webTemplateId);
    },
    itemHasVoucherDetails(item) {
      if (!item?.Persons && !item?.Nights && !item?.Catering) return false;
      return !this.itemIsDiscountVoucher(item);
    },
    showExceedQuantityMessage() {
      this.$alert("Leider sind keine weiteren Artikel dieser Art verfügbar.");
    },
    getMaxQuantity(item) {
      const availability = this.getAvailability(item);
      if (!availability) return 0;
      return item.IsDiscounted
        ? availability.AvailableDealQuantity
        : availability.AvailableRegularQuantity;
    },
    getAvailability(item) {
      return (
        this.availabilities.length &&
        this.availabilities.find((a) => a.ItemId == item.id)
      );
    },
    async loadAvailabilities(shoppingBasketItems) {
      if (!Array.isArray(shoppingBasketItems) || !shoppingBasketItems.length)
        return [];
      return await this.$apollo
        .query({
          query: Items.Queries.AvailableShoppingBasketItemQuantities,
          variables: {
            shoppingBasketItems
          }
        })
        .then(
          (response) =>
            response?.data?.availableShoppingBasketItemQuantities || []
        );
    },
    async updateAvailabilities() {
      try {
        const shoppingBasketItems = createCheckoutBasket(this.items);
        this.availabilities = await this.loadAvailabilities(
          shoppingBasketItems
        );
      } catch (e) {
        this.$log.error(e);
      }
    },
    async changeItemQuantity(item, quantity) {
      if (quantity > this.getMaxQuantity(item)) return;
      return await this.$shoppingBasket.changeItemQuantity(item, quantity);
    }
  }
};
</script>
<style scoped lang="scss">
.shopping-basket-items {
  .itm {
    &:not(:first-child) {
      margin-top: 1rem;
    }
  }
}
</style>
