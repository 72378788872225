<template lang="pug">
  .modal-dialog.m-0.txt-black
    .modal-content.bg-tdays-light
      .modal-header.position-relative
        .star
          img.img-fluid.w-100(src="https://td-cloud.s3.eu-central-1.amazonaws.com/Images/makaira/marketing/xmas/xmas-2021/xmas-star-2021.png")
      .modal-body.p-0.m-0.text-center
        .px-3
          b Geteiltes Glück ist doppeltes Glück!
        br
        .px-3 Teilen Sie das Glück mit Ihren Lieben, um Ihre Gewinnchance zu erhöhen.
        br
        .px-3 Mit jedem Teilnehmer, der durch Ihre Empfehlung am Gewinnspiel teilnimmt, landen Sie ein weiteres Mal im Los-Topf. So können Sie Ihre Gewinnchance schnell und einfach vervielfachen.
        br
        .px-3 Jetzt einfach anmelden und den angegebenen Link teilen!
      .modal-footer
        .py-2.px-3.text-center
          button.button.button-secondary.button-tdays.txt-white(@click="$emit('close')") schließen
</template>

<script>
export default {
  name: "XmasInfoModal",
};
</script>
<style>
.xmas-modal.xmas-info-modal {
  overflow: visible !important;
}
.modal-header,
.modal-footer {
  border: none;
}
</style>
<style scoped>
.star {
  position: absolute;
  width: 110px;
  left: 50%;
  margin-left: -55px;
  top: 0;
  margin-top: -55px;
}
</style>
