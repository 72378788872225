import gql from "graphql-tag";

export const MarketingPromoContestPrizes = {
    Queries: {
        MarketingPromoContestPrizesByDrawPrizeAt: gql`
        query marketingPromoContestPrizesByDrawPrizeAt($marketingPromoContestId: ID, $drawPrizeAt: Date) {
            marketingPromoContestPrizesByDrawPrizeAt(marketingPromoContestId: $marketingPromoContestId, drawPrizeAt: $drawPrizeAt) {
                id
                HtmlHeadline
                HtmlSubline
                HtmlDescription
                ImageUrl
                Code
                DetailUrl
                IsDiscountOnlyPrize
                DrawPrizeAt
                Quantity
                Value
                WinnerNotifiedAt
                PrizeDrawnAt
                createdAt
                updatedAt
                deletedAt
                MarketingPromoContestId
                ItemId
                MarketingPromoParticipantId
            }
        }`,
        NextDrawnMarketingPromoContestPrize: gql`
            query nextDrawnMarketingPromoContestPrize($marketingPromoContestId: ID) {
                nextDrawnMarketingPromoContestPrize(marketingPromoContestId: $marketingPromoContestId) {
                    DrawPrizeAt
                }
            }`
    },
};
