<template lang="pug">
  HostPreviewBox(v-bind="hostAttributes" :link-to-new-tab="linkToNewTab")
    template(v-if="showsPrice")
      HostPreviewPrice(:items="hostItems" v-if="hostItems.length > 0")
      .loader(style="width:100px;border-radius:5px;" v-else) &nbsp;
    template(v-slot:gallery)
      PreviewHostGallery(:images="hostImages")
    template(v-slot:special)
      slot(name="special" :host="host" :items="hostItems")
    template(v-slot:footer v-if="showVoucherDetails")
      HostPreviewFooter.py-2(:persons="personsRange" :nights="nightsRange" :catering="cateringRange")
</template>
<script>
import HostPreviewBox from "@/views/components/hostPreview/partials/HostPreviewBox";
import HostPreviewPrice from "@/views/components/hostPreview/partials/HostPreviewPrice";
import HostPreviewFooter from "@/views/components/hostPreview/partials/HostPreviewFooter";
import { availableCaterings } from "@/lib/helper/caterings";
import { getHighestDiscountForItems, orderItemsAscendingByPrice } from "@/lib/helper/items";
import PreviewHostGallery from "@/views/components/host/gallery/PreviewHostGallery";
import { getDisplayableHostImages } from "@/lib/helper/host";

const getItemAttributeRange = (items, attribute) => {
  const sortedValues = items.flatMap((item) => item[attribute]).sort((a, b) => a - b);
  const min = sortedValues[0];
  const max = sortedValues[sortedValues.length - 1];
  if (min === max) return min;
  return [min, max];
};

export default {
  name: "HostPreviewComponent",
  components: {
    HostPreviewFooter,
    HostPreviewPrice,
    HostPreviewBox,
    PreviewHostGallery
  },
  props: {
    host: Object,
    items: Array,
    selectedItemId: [Number, String],
    showsPrice: {
      type: Boolean,
      default: true
    },
    showVoucherDetails: {
      type: Boolean,
      required: false,
      default: true
    },
    isTippsHost: Boolean,
    linkToNewTab: Boolean
  },
  computed: {
    hostItems() {
      const items = this?.items || this.host?.Items || [];
      return orderItemsAscendingByPrice(items);
    },
    hostImages() {
      try {
        if (!this.host.Images || this.host.Images.length === 0) return [];
        if (this.host.Images.every((image) => !image.RemoteURL)) {
          return this.host.Images;
        }
        return getDisplayableHostImages(this.host).map((image) => {
          // map image from host to the format required by the gallery
          const seoData = image.Seo;
          return {
            url: image.url || image.RemoteURL,
            alt: seoData?.ImageAlternativeText,
            title: seoData?.ImageDescription
          };
        });
      } catch (e) {
        this.$log.error(e);
        return [];
      }
    },
    hostAttributes() {
      let selectedItemId = this.selectedItemId;
      if (this.hostItems && this.hostItems.length && this.hostItems[0].id) selectedItemId = this.hostItems[0].id;
      const url = selectedItemId ? `/gastgeber/${this.host.Url}${this.isTippsHost ? "?tipps=true" : ""}#${selectedItemId}` : undefined;
      return {
        stars: this.host?.Stars ? parseInt(this.host.Stars) : undefined,
        isSuperior: this.host?.Stars?.toLowerCase()?.includes("s"),
        name: this.host.Name,
        cityRegion: this.host.CityRegion,
        discountPercentage: getHighestDiscountForItems(this.hostItems),
        isTippsHost: this.isTippsHost,
        url
      };
    },
    personsRange() {
      if (!this.hostItems) return undefined;
      return getItemAttributeRange(this.hostItems, "Persons");
    },
    nightsRange() {
      if (!this.hostItems) return undefined;
      return getItemAttributeRange(this.hostItems, "Nights");
    },
    cateringRange() {
      if (!this.hostItems) return undefined;
      const sortedValues = this.hostItems
        .filter((item) => {
          return !!availableCaterings.find((catering) => catering.value === item.Catering);
        })
        .sort((a, b) => {
          const indexA = availableCaterings.find((catering) => catering.value === a.Catering);
          const indexB = availableCaterings.find((catering) => catering.value === b.Catering);
          return indexA - indexB;
        })
        .map((item) => item.Catering);

      const min = sortedValues[0];
      const max = sortedValues[sortedValues.length - 1];
      if (min === max) return min;
      return [min, max];
    }
  }
};
</script>
