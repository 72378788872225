import { mergeDeep } from "@/lib/helper/object";

export const createConfig = (mode) => {
  mode = mode || process?.env?.NODE_ENV || "development";
  const baseConfig = {
    graphQl: {
      uri: "http://localhost:5225/graphql"
    },
    server: {
      host: "http://localhost",
      port: 5000
    },
    runMode: mode,
    external: {
      google: {
        geocoder: "AIzaSyBWI5arkEJpEs2qLS6QEcFLDPOg29g0nbM",
        gtag: "GTM-PHP95PK",
        recaptcha: {
          siteKey: "6LcAl7EZAAAAAGD9baLn2ho12hm1ydpwo3y544Rd"
        }
      },
      awin: "14416",
      cookieBot: "4b3f7246-1dfb-49cc-912c-8a64096b5972",
      facebook: "2527044380845637",
      elastic: {
        engineName: "search-items",
        endpointBase: "https://td-elastic-dev.ent.eu-central-1.aws.cloud.es.io",
        searchKey: "search-opm1kk8hftn3m4eo3hmxzdpr",
        privateKey: "private-e9u3mdku6tztu1p3g775yk7b",
        suggestionFields: ["name", "city_region"],
        debug: process?.env?.NODE_ENV === "development"
      },
      apple: {
        mapkit:
          "eyJraWQiOiJKUlNMTUI1VTY2IiwidHlwIjoiSldUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJRNjJTSzdUNDk2IiwiaWF0IjoxNzIyNDEzNDkzLCJvcmlnaW4iOiIqLm1vbG9zcy5sb2NhbCJ9.jTK1JA_IiYRDPFd8uMkD5DLXEVhMPKgutODCuNDqz7Tul6p3c5rq01hd6-kM5HQ7R7u8BGFuhls9K5_7Q629QA"
      },
      amazon: {
        publicKeyId: "SANDBOX-AEDYNYP7NNDIC375OF2TOANZ",
        sandbox: true
      }
    },
    marketing: {
      easterEventId: 10
    },
    reisereporterRefId: 156
  };

  switch (mode) {
    default:
      return baseConfig;
    case "development":
      // use elastic production environment when running in dev mode (dev engine contains data from dev-db)
      return mergeDeep(baseConfig, {
        external: {
          elastic: {
            //engineName:'search-items',
            endpointBase:
              "https://td-elastic-cluster.ent.eu-central-1.aws.cloud.es.io",
            searchKey: "search-4hoq4mpifpcpjvfw78ayb3ct",
            privateKey: "private-im9m1i95ya2wazdpokogyw4u"
          },
          apple: {
            mapkit:
              "eyJraWQiOiJKUlNMTUI1VTY2IiwidHlwIjoiSldUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJRNjJTSzdUNDk2IiwiaWF0IjoxNzIyNDEzNDkzLCJvcmlnaW4iOiIqLm1vbG9zcy5sb2NhbCJ9.jTK1JA_IiYRDPFd8uMkD5DLXEVhMPKgutODCuNDqz7Tul6p3c5rq01hd6-kM5HQ7R7u8BGFuhls9K5_7Q629QA"
          }
        }
      });
    case "staging":
      return mergeDeep(baseConfig, {
        graphQl: {
          uri: "https://moloss-apollo-dev.hub-01.touri.dev/graphql"
        },
        server: {
          host: "https://moloss.hub.touri.dev"
        },
        external: {
          apple: {
            mapkit:
              "eyJraWQiOiJRU1hLOTg5UEZQIiwidHlwIjoiSldUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJRNjJTSzdUNDk2IiwiaWF0IjoxNzIyNDEzNDkzLCJvcmlnaW4iOiIqLm1vbG9zcy13ZWItYXYyLnBhZ2VzLmRldiJ9.Mx_rifsKMN1x7esfh3gJac6x4XhGxTxNqjYLmJww0RB4oqrAUcjlt1Lj456Kk0kjCHbK4jRl4FVAbW5fSlN4PQ"
          }
        }
      });
    case "production":
      return mergeDeep(baseConfig, {
        graphQl: {
          uri: "https://moloss-apollo.htzn-cap-01.touri.dev/graphql"
        },
        server: {
          host: "https://touridat.com"
        },
        external: {
          google: {
            gtag: "GTM-PMDK2W",
            recaptcha: {
              siteKey: "6LcAl7EZAAAAAGD9baLn2ho12hm1ydpwo3y544Rd"
            }
          },
          awin: "14416",
          cookieBot: "5a8636e1-2ad3-4999-a384-c33706439e40",
          facebook: "224554821067470",
          elastic: {
            endpointBase:
              "https://td-elastic-cluster.ent.eu-central-1.aws.cloud.es.io",
            searchKey: "search-4hoq4mpifpcpjvfw78ayb3ct",
            privateKey: "private-im9m1i95ya2wazdpokogyw4u"
          },
          amazon: {
            publicKeyId: "LIVE-AEZ2JV7E24AV43P2R6UIZK6M",
            sandbox: false
          },
          apple: {
            mapkit:
              "eyJraWQiOiI3NjdXR0w3Qlg4IiwidHlwIjoiSldUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJRNjJTSzdUNDk2IiwiaWF0IjoxNzIyNDEzNDkzLCJvcmlnaW4iOiIqLnRvdXJpZGF0LmNvbSJ9.Qf0xvkPyzXyxZ3LtOM2ufq8a1dzZ3hAPBQ6rkpSoP4pSXO3-Ilfc_2eozvcOU5TuG28b4r1BVVE6WhOczqP0_w"
          }
        }
      });
    case "app":
      return mergeDeep(baseConfig, {
        server: {
          host: "https://app.touridat.com"
        },
        graphQl: {
          uri: "https://moloss-apollo.htzn-cap-01.touri.dev/graphql"
        },
        external: {
          google: {
            gtag: "GTM-PMDK2W",
            recaptcha: {
              siteKey: "6LcAl7EZAAAAAGD9baLn2ho12hm1ydpwo3y544Rd"
            }
          },
          awin: "14416",
          cookieBot: "5a8636e1-2ad3-4999-a384-c33706439e40",
          facebook: "224554821067470"
        }
      });
  }
};

export default createConfig();
