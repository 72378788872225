<template lang="pug">
  #app(:data-version="appVersion" :class="{'mobile-app':hasMobileFrame}")
    MobileFrameHeader(v-if="hasMobileFrame")
    AppLoadingSpinner(:is-visible="isLoading")
    .widgets
      ShoppingBasket(v-if="showsShoppingBasket" @close="showsShoppingBasket = false")
      //Transition(name="shoppingBasket" @enter="showsShoppingBasket = true" @before-leave="showsShoppingBasket = false")
        ShoppingBasket(v-if="showsShoppingBasketOverlay" :shows-container="showsShoppingBasket" @close="showsShoppingBasket = false" @destroy="showsShoppingBasketOverlay = false")
      ShoppingBasketButton.position-fixed.basket-widget.p-3(v-if="showsShoppingBasketButton" @click="toggleShoppingBasket" style="--quantity-offset:0px;")
      ChatWidget.userlike-fixed-icon(v-if="hasChatWidget" account-id="091e1339-50c4-4285-930c-7fcba85f78ec")
        font-awesome-icon(icon="comments")
    #viewport
      //.tech-info.p-3.container(v-if="showUpdateMessage")
          .h3 Liebe Gäste,
          p aufgrund eines Systemupdates kann es aktuell zu leichten Verzögerungen Ihrer Kaufabwicklung kommen. Bitte haben Sie noch etwas Geduld, wir lassen Sie nicht auf gepackten Koffern sitzen. 😊
          p Ihr touriDat-Team
          button.button.button-primary.button-tdays(@click="showUpdateMessage = false") Ausblenden
      .bg-ttipps-lighter(v-if="showsEasterInfo")
        .container.text-center
          .d-inline-flex.align-items-center.py-2
            EasterEgg.txt-tdays-light.mr-4.d-none.d-md-flex(style="width:45px;cursor:default!important;")
            div Sichern Sie sich jetzt Ihre Osterüberraschung:
            button.button.button-primary.button-tdays.ml-2(@click="showEasterModal" style="padding: 0.5rem 1rem;") Teilnehmen
            EasterEgg.txt-tpics-basic.ml-4.d-none.d-md-flex(style="width:45px;cursor:default!important;")
      AppHeader(:template-type="currentWebTemplateType" :shows-top-nav="!hasMobileFrame")
      main.pb-5
        EasterEgg.position-absolute(v-if="showsEgg" @collected="showsEgg = false" random interactable)
        MainNav.py-3(:type="currentWebTemplateType" :shows-shopping-basket-button="showsShoppingBasketButton")
          template(v-slot:additionalButtons)
            ShoppingBasketButton.d-none.d-sm-inline-block.button-icon(v-if="showsShoppingBasketButton" @click="toggleShoppingBasket")
        router-view(v-if="!error" :key="$route.path")
        Error(v-else :error="error")
      BottomNavigation
      AppFooter
    MobileFrameFooter(v-if="hasMobileFrame" :template-type="currentWebTemplateType")
</template>

<script>
import ShoppingBasket from "@/views/components/shoppingBasket/ShoppingBasket";
import LoadingSpinnerOverlay from "@/views/components/loading/LoadingSpinnerOverlay";
import ChatWidget from "@touridev/chat-widget";

import { version as appVersion } from "../package.json";
import logOptions from "@/config/log";
import AppHeader from "@/views/components/header/AppHeader";
import AppFooter from "@/views/components/footer/AppFooter";
import EventBus from "@/event-bus";
import { kebabize } from "@/lib/helper/strings";
import BottomNavigation from "@/views/components/footer/BottomNavigation";
import ContentHeader from "@/views/components/header/ContentHeader";
import WebTemplatesMap from "@/utils/WebTemplatesMap";
import MainNav from "@/views/components/mainNav/MainNav";
import MobileFrameHeader from "@/views/components/mobile/frame/MobileFrameHeader";
import MobileFrameFooter from "@/views/components/mobile/frame/MobileFrameFooter";
import AppLoadingSpinner from "@/views/components/loading/AppLoadingSpinner";
import config from "@/config/config";
import { setupShoppingBasket } from "@/lib/plugins/shoppingBasket";
import ShoppingBasketButton from "@/views/components/shoppingBasket/ShoppingBasketButton.vue";
import { Referrers } from "@/graphql/Referrers.ts";
import { sendVisitFromReferrer } from "@/lib/helper/gtm";
import { authUser } from "@/lib/helper/user";
import { mapState } from "pinia";
import { useErrorStore } from "@/store/error-store";
import Error from "@/views/error/Error.vue";
import EasterEgg from "@/views/static/easter/partials/EasterEgg.vue";
import { MarketingPromoContest } from "@/graphql/MarketingPromoContest.ts";
import moment from "moment";
import EasterSignupModal from "@/views/components/modals/easterSignup/EasterSignupModal.vue";
/*import NewsletterPopup from "@/views/components/modals/newsletterPopup/newsletterPopup.vue";*/

export default {
  name: "App",
  components: {
    EasterEgg,
    Error,
    ShoppingBasketButton,
    AppLoadingSpinner,
    MobileFrameFooter,
    MobileFrameHeader,
    MainNav,
    ContentHeader,
    BottomNavigation,
    AppFooter,
    AppHeader,
    ShoppingBasket,
    ChatWidget,
    LoadingSpinnerOverlay
  },
  setup() {
    return {
      appVersion,
      reisereporterRefId: config.reisereporterRefId
    };
  },
  data() {
    return {
      showUpdateMessage: false,
      isLoading: false,
      hasChatWidget: false,
      showsShoppingBasket: false,
      currentWebTemplateType: undefined,

      hasActiveEasterEvent: false,
      showsEgg: false,
      showsEasterInfo: false
    };
  },
  methods: {
    showEasterModal() {
      this.$modal.show(
        EasterSignupModal,
        {},
        {
          width: "520px",
          height: "auto",
          classes: [
            "modalMaxHeight",
            "rounded-0",
            "overflow-initial"
          ]
        }, {
          closed: () => {
            this.$session.set("hasClosedEasterModal", true);
            if (this.$session.has("easterParticipantId")) {
              this.showsEasterInfo = false;
              return;
            }
            this.showsEasterInfo = this.hasActiveEasterEvent;
          }
        }
      );
    },
    toggleShoppingBasket() {
      this.showsShoppingBasket = !this.showsShoppingBasket;
    },
    onBeforeUnload() {
      // remove referrerId from the session
      this.$session.remove("referrerId");
    }
  },
  computed: {
    ...mapState(useErrorStore, ["error"]),
    shouldShowNewsletterPopup() {
      if (this.$route.name === "static-newsletter") return true;
      const hideTill = this.$session.get("hideNlDiscountPopupTill");
      if (!hideTill) return true;
      return moment(hideTill).isSameOrBefore(new Date());
    },
    showsShoppingBasketButton() {
      return (
        this.$route.name != "Checkout" && this.$shoppingBasket.items.length > 0
      );
    },
    currentWebTemplateClass() {
      try {
        const webTemplate = WebTemplatesMap.find(
          (template) => template.type === this.currentWebTemplateType
        );
        if (!webTemplate?.templateClass) return;
        return webTemplate.templateClass;
      } catch (e) {
        this.$log.error(e);
        return undefined;
      }
    },
    hasMobileFrame() {
      try {
        if (!this.$touriConfig || !this.$touriConfig.runMode) return false;
        return this.$touriConfig.runMode === "app";
      } catch (e) {
        this.$log.error(e);
        return false;
      }
    }
  },
  watch: {
    $route(to, from) {
      useErrorStore().setError(undefined);
      try {
        // this will set data-view on the html element to the current route name each time the route changes.
        // this makes it possible to query elements from inside a view component that are outside main content element.
        // for example there could be a view component that needs to hide the header.
        // value will be lowercase kebab
        const viewName = this.$route.name;
        if (!viewName) {
          document.documentElement.removeAttribute("data-view");
          return;
        }
        document.documentElement.setAttribute("data-view", kebabize(viewName));
      } catch (e) {
        this.$log.error(e);
      }

      try {
        // scroll to top if the route changes
        if (to.path !== from.path) this.$scrollTo("#app");
        this.showsEgg = this.hasActiveEasterEvent && this.$session.has("easterParticipantId");
      } catch (e) {
        this.$log.error(e);
      }
    },
    currentWebTemplateClass(className) {
      document.body.dataset.template = className;
    }
  },
  created() {
    window?.addEventListener("beforeunload", this.onBeforeUnload);
    // start the session
    try {
      if (!this.$session.exists()) {
        this.$session.start();
      }
    } catch (e) {
      this.$log.error(e);
    }

    // save a default language to the session if it doesn't have one
    try {
      if (!this.$session.get("lang")) {
        this.$session.set("lang", "de");
      }
    } catch (e) {
      this.$log.error(e);
    }

    // register event listener for "changeLoadingState"
    try {
      EventBus.$on("changeLoadingState", (loadingState) => {
        this.isLoading = loadingState;
      });
    } catch (e) {
      this.$log.error(e);
    }

    // register event listener for opening / closing the shopping basket
    try {
      EventBus.$on("showShoppingBasket", (value) => {
        if (typeof value !== "boolean") return;
        this.showsShoppingBasket = value;
      });
    } catch (e) {
      this.$log.error(e);
    }

    // register event listener for "changeWebTemplateType"
    try {
      EventBus.$on("changeWebTemplateType", (type) => {
        // set the current web template type to the given type. if type is undefined, fall back to the default value
        this.currentWebTemplateType = type || this.currentWebTemplateType;
      });
    } catch (e) {
      this.$log.error(e);
    }

    // change theme if referred by reisereporter
    try {
      if (this.$session.get("referrerId") == config.reisereporterRefId)
        document.body.classList.add("reisereporter");
    } catch (e) {
      this.$log.error(e);
    }

    // trigger gtm event to send referrer if there is any
    try {
      const refId = this.$session.get("referrerId");
      if (refId) {
        this.$apollo
          .query({
            query: Referrers.Queries.Referrer,
            variables: {
              value: refId
            }
          })
          .then((response) => {
            const referrer = response?.data?.referrer;
            if (!referrer) return;
            sendVisitFromReferrer(referrer?.Name);
          });
      }
    } catch (e) {
      this.$log.error(e);
    }
  },
  async mounted() {
    if (logOptions.logLevel === "debug")
      console.log(
        `%cmoloss v${this.appVersion} - running in ${this.$touriConfig.runMode} mode`,
        "color:#488345;font-weight:bold;"
      );
    try {
      await setupShoppingBasket(
        this.$shoppingBasket,
        this.$apollo,
        this.$session
      );
      if (this.$session.has("token"))
        await authUser(false, this.$apollo, this.$shoppingBasket, this.$modal);
    } catch (e) {
      this.$log.error(e);
    }

    if (config.marketing.easterEventId) {
      const easterContest = await this.$apollo.query({
        query: MarketingPromoContest.Queries.MarketingPromoContest,
        variables: {
          marketingPromoContestId: config.marketing.easterEventId
        }
      }).then((response) => response?.data?.marketingPromoContest);
      const now = moment(new Date());
      this.hasActiveEasterEvent = (
        now.isSameOrBefore(easterContest.RegistrationTill) &&
        now.isSameOrAfter(easterContest.RegistrationFrom)
      );
      if (this.hasActiveEasterEvent) {
        if (this.$session.get("easterParticipantId")) {
          this.showsEgg = true;
        }
        if (this.$session.get("hasClosedEasterModal")) {
          this.showsEasterInfo = !this.$session.has("easterParticipantId");
          return;
        }
        this.showEasterModal();
      }
    }
    /*if (this.shouldShowNewsletterPopup) {
      this.$modal.show(NewsletterPopup, {}, {
        clickToClose: false,
        width: "430px",
        height: "auto",
        classes: [
          "rounded-0",
          "newsletter-modal"
        ]
      });
    }*/
  },
  metaInfo() {
    try {
      return {
        title: "Reise- & Hotelgutscheine günstig kaufen & verschenken",
        titleTemplate: "%s | touriDat.com",
        htmlAttrs: {
          lang: "de",
          amp: false
        },
        meta: [
          {
            vmid: "og:url",
            name: "og:url",
            content: window.location.href
          },
          {
            property: "fb:app_id",
            content: this.$touriConfig.external.facebook
          },
          {
            name: "description",
            vmid: "description",
            content: "Reisegutscheine & Hotelgutscheine kaufen und verschenken ✔ geprüfter Testsieger seit 2018 ✔ 100% Käuferschutz ✔ Jetzt buchen!"
          }
        ],
        link: [
          {
            vmid: "canonical",
            rel: "canonical",
            href: window.location.href
          }
        ],
        script: [
          {
            src: "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js",
            type: "text/javascript",
            async: true
          }
        ]
      };
    } catch (e) {
      this.$log.error(e);
      return {};
    }
  }
};
</script>

<style lang="scss">
@import "./assets/styles/base.scss";
</style>

<style scoped lang="scss">
@import "./assets/styles/_colors.scss";
@import "./assets/styles/mediaqueries";

.widgets {
  position: relative;
  z-index: 10;

  .basket-widget {
    position: relative;
    border-radius: 50%;
    font-size: 1rem;
    bottom: 5px;
    right: 5px;
    @include md {
      font-size: 1.4rem;
      bottom: 25px;
      right: 25px;
    }

    &:after {
      content: "";
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: $td-grey-basic;
      border-radius: inherit;
      position: absolute;
      z-index: -1;
      isolation: isolate;
      animation: pulseAnimation 6s linear infinite normal forwards;

      @keyframes pulseAnimation {
        0% {
          transform: scale(1);
          opacity: 0;
        }
        37.5% {
          transform: scale(1.35);
          opacity: 0.2;
        }
        50%,
        100% {
          transform: scale(1.5);
          opacity: 0;
        }
      }
    }
  }
}

main {
  transition: opacity 0.4s;

  &.opaque {
    opacity: 0;
  }
}
</style>
