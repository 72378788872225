<template lang="pug">
  a#xmas-banner.d-block(href="/adventskalender-2023")
    img.lozad.right-sided(src="https://td-cloud.s3.eu-central-1.amazonaws.com/Images/makaira/marketing/xmas/littletstars-right-sided.png" alt="kleine Sterne rechts")
    img.lozad.left-sided(src="https://td-cloud.s3.eu-central-1.amazonaws.com/Images/makaira/marketing/xmas/littletstars-left-sided.png" alt="kleine Sterne links")
    .container.text-center
      .d-inline-block.d-md-inline-flex.align-items-center.justify-content-between(style="gap:1rem")
        div.small.text-shadow
            div Der beliebte touriDat-Adventskalender:
            .font-weight-bold.text-center
              | Gewinnen Sie mehr als 300 Preise
              br.d-block.d-md-none
              | &nbsp;im Wert von über 45.000 EUR!
        span.button.button-primary.button-tdays jetzt anmelden
</template>

<script>
export default {
  name: "XmasHeader",
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/xmas-2022.scss";
#xmas-banner {
  user-select: none;
  background: $xmas-brown-light;
  position: relative;
  padding: 8px 0;
}

#xmas-banner img {
  position: absolute;
  height: 40px;
}

#xmas-banner img.left-sided {
  left: 0;
  bottom: 0;
}

#xmas-banner img.right-sided {
  right: 0;
  top: 0;
}
.button {
  padding: 5px 10px;
  font-size: 12px;
}
</style>
