<template lang="pug">
.accordion-selector(@change="selectOption")
  slot(name="default")
</template>

<script>
export default {
  name: "AccordionSelector",
  props:{
    tabColor:{
      type:String,
      default:'#c2c2c2',
    },
    titleColor:{
      type:String,
      default:'#fff',
    },
  },
  data(){
    return {
      optionIds:[]
    }
  },
  methods:{
    selectOption(e){
      if(e.target?.dataset?.value){
        this.$emit('change',e.target.dataset.value);
      }
    }
  }
}
</script>