<template lang="pug">
router-link.d-block.host-item-menu-item(:class="{unavailable:quantityState === QuantityStateEnum.Unavailable}" :to="itemRoute")
    .item-inner
      header.small.d-flex.align-items-center.justify-content-between.px-2(v-if="quantityState" :class="quantityStateData[quantityState].class")
        span {{quantityStateData[quantityState].message}}
        font-awesome-icon(v-if="quantityStateData[quantityState].icon" :icon="quantityStateData[quantityState].icon")
      .position-relative
        template(v-if="indicateActive")
          .active-arrow.position-absolute
          .active-indicator.position-absolute.w-100.h-100(style="left:0;top:0;")
            .active-radius.position-absolute
        .d-flex.justify-content-between.position-relative(:class="size === 'large' ? 'align-items-stretch' : 'align-items-center'")
          .features.d-flex.justify-content-between.py-2(v-if="size === 'large'" style="flex-direction: column;")
              .feature.d-flex.align-items-center(v-for="feature in itemFeatures")
                .icon.px-2
                    font-awesome-icon(:icon="feature.icon")
                .value {{feature.title}}
              slot(name="customFeature" :item="item")
          .inline-buttons.d-flex(v-else)
              slot(name="buttons")
              //AddToShoppingBasketButton.button.button-primary.button-tdays.p-3.mr-1.pointer-events-all(v-if="item.id" :item-id="item.id" :is-tipps-item="hasTippsDiscountApplied" :disabled="!isItemPurchasable(item)")
                font-awesome-icon(icon="shopping-cart")
              //button.button.button-primary.button-tdays.p-3.pointer-events-all(title="telefonisch bestellen" @click="emitPhoneOrder")
                font-awesome-icon(icon="phone")
          .prices(:class="size === 'large' ? 'text-right' : 'd-flex align-items-end justify-content-center w-100'")
            .discount-badge.txt-white.small.d-inline-block.p-1.mr-2(v-if="discountPercentage && size === 'large'" :class="[QuantityStateEnum.Unavailable,QuantityStateEnum.AvailableWithLiveShopping].includes(quantityState) ? quantityStateData[quantityState].class : 'bg-tdays-basic'") -{{discountPercentage | formatPercentage}}
            .regular-price.line-through(v-if="regularPrice && showRegularPrice") {{regularPrice | formatPrice}}
            .current-price.h2.m-0(v-if="currentPrice") {{currentPrice | formatPrice}}
            .discount-badge.small-badge.txt-white.small.d-inline-block.p-1.mr-2.position-absolute(v-if="discountPercentage && size === 'small'" :class="[QuantityStateEnum.Unavailable,QuantityStateEnum.AvailableWithLiveShopping].includes(quantityState) ? quantityStateData[quantityState].class : 'bg-tdays-basic'") -{{discountPercentage | formatPercentage}}
      .special.small.d-flex.align-items-center.justify-content-between.px-2.txt-white(v-if="productSpecial") {{productSpecial}}
    footer
      template(v-if="isInLiveShopping && liveShoppingDeadline")
        FlipCountdown.countdown(v-if="size === 'large'" :deadline="liveShoppingDeadline")
        .small.text-center(v-else)
          | Noch&nbsp;
          TextCountdown.d-inline-block(:deadline="liveShoppingDeadline")
      template(v-else-if="quantityState !== QuantityStateEnum.Unavailable && hasTippsDiscount && discountValue")
        TippsDiscount(:is-applied="hasTippsDiscountApplied" :disabled="quantityState === QuantityStateEnum.AvailableRegularOnly" :value="discountValue" @change="$emit('changeTipps',$event)")
          template(v-slot:logo)
            div(:class="size === 'large' ? 'p-1 p-sm-2' : 'p-1'")
              img.tipps-logo(src="https://s3-cloud.td-cdn.de/Images/Logos/touritipps/white-plain/only-mark/touritipps-white-plain-only-mark-height-55.png" loading="lazy")
</template>
<script>
import { clamp } from "@/lib/helper/math";
import { QuantityStateEnum } from "@/utils/QuantityStateEnum.ts";
import {
  getDealQuantity,
  getItemSpecial,
  getLiveShoppingDeadline,
  getRegularQuantity,
  getSimpleItemAccommodation,
} from "@/lib/helper/items";
import AddToShoppingBasketButton from "@/views/components/partials/buttons/addToShoppingBasket/AddToShoppingBasketButton.vue";
import FlipCountdown from "@/views/components/partials/countdown/FlipCountdown.vue";
import TextCountdown from "@/views/components/partials/countdown/TextCountdown.vue";
import TippsDiscount from "@/views/host/partials/tippsDiscount/TippsDiscount.vue";
export default {
  name: "HostItemMenuItem",
  emits: ["changeTipps", "phoneOrder"],
  components: {
    TippsDiscount,
    TextCountdown,
    FlipCountdown,
    AddToShoppingBasketButton,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    showAccommodation: {
      type: Boolean,
      default: true,
    },
    showRegularPrice: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      validator(value) {
        return ["small", "large"].includes(value);
      },
      default() {
        return "large";
      },
    },
    hasTippsDiscount: Boolean,
    hasTippsDiscountApplied: Boolean,
    indicateActive: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const quantityStateData = {
      [QuantityStateEnum.AvailableWithLiveShopping]: {
        class: "bg-tpics-basic txt-white",
        message: "Momentan zum Dealpreis erhältlich",
        icon: "hourglass",
      },
      [QuantityStateEnum.AvailableDealOnly]: {
        class: "bg-ttipps-light txt-white",
        message: "Zurzeit nur zum touriTipps-Aktionspreis verfügbar",
      },
      [QuantityStateEnum.AvailableRegularOnly]: {
        class: "bg-td-grey-basic txt-white",
        message: "Zurzeit nicht zum touriTipps-Aktionspreis verfügbar",
      },
      [QuantityStateEnum.Unavailable]: {
        class: "bg-td-grey-basic txt-white",
        message: "Zurzeit leider nicht verfügbar",
      },
    };

    return { quantityStateData, QuantityStateEnum };
  },
  computed: {
    itemRoute() {
      return {
        ...this.$route,
        hash: `#${this.item.id}`,
      };
    },
    liveShoppingDeadline() {
      if (!this.item.AdvertisedTill) return;
      return getLiveShoppingDeadline(this.item);
    },
    quantityState() {
      const isVoucherItem = [4, 6].includes(this.item.WebTemplateId);
      if (isVoucherItem && this.item.Quantity)
        return QuantityStateEnum.Available;
      if (!this.dealQuantity && !this.regularQuantity)
        return QuantityStateEnum.Unavailable;
      const quantity =
        this.isInLiveShopping || this.hasTippsDiscountApplied
          ? this.dealQuantity
          : this.regularQuantity;
      if (!this.dealQuantity && this.hasTippsDiscount && this.regularQuantity)
        return QuantityStateEnum.AvailableRegularOnly;
      if (this.hasTippsDiscount && !this.regularQuantity)
        return QuantityStateEnum.AvailableDealOnly;
      if (!quantity) return QuantityStateEnum.Unavailable;
      if (this.isInLiveShopping)
        return QuantityStateEnum.AvailableWithLiveShopping;
      return QuantityStateEnum.Available;
    },
    isInLiveShopping() {
      //return (this.item?.IsInLiveShopping && this.dealQuantity) || false;
      return this.item?.IsInLiveShopping || false;
    },
    isDiscounted() {
      return this.isInLiveShopping || this.hasTippsDiscountApplied;
    },
    regularQuantity() {
      return getRegularQuantity(this.item);
    },
    dealQuantity() {
      return getDealQuantity(this.item);
    },
    quantity() {
      return this.isDiscounted ? this.dealQuantity : this.regularQuantity;
    },
    regularPrice() {
      return this.item?.RegularPrice || 0;
    },
    discountedPrice() {
      return this.item?.DiscountedPrice || 0;
    },
    dealPrice() {
      return this.item?.LiveShoppingPrice || 0;
    },
    currentPrice() {
      return this.isDiscounted ? this.dealPrice : this.discountedPrice;
    },
    discountValue() {
      return clamp(this.discountedPrice - this.dealPrice, 0, this.currentPrice);
    },
    discountPercentage() {
      const discountPercentage =
        ((this.regularPrice - this.currentPrice) / this.regularPrice) * 100;
      return clamp(0, discountPercentage, 100);
    },
    itemFeatures() {
      const features = [
        {
          icon: "moon",
          title: this.formattedNights,
          value: this.nights,
        },
        {
          icon: "user-group",
          title: this.formattedPersons,
          value: this.persons,
        },
      ];
      if (
        this.showAccommodation &&
        this.accommodation?.trim()?.length &&
        this.size === "large"
      )
        features.push({
          icon: "bed",
          title: this.accommodation,
          value: this.accommodation,
        });
      return features;
    },
    accommodation() {
      return getSimpleItemAccommodation(this.item);
    },
    productSpecial() {
      const isStaticVoucherItem = [6].includes(this.item.WebTemplateId);
      if (isStaticVoucherItem) return;
      return getItemSpecial(this.item);
    },
    formattedNights() {
      const nights = this.item?.Nights || 0;
      if (!nights) return;
      if (nights === 1) return `1 Nacht`;
      return `${nights} Nächte`;
    },
    formattedPersons() {
      const persons = this.item?.Persons || 0;
      if (!persons) return;
      if (persons === 1) return `1 Person`;
      return `${persons} Personen`;
    },
  },
  methods: {
    emitPhoneOrder() {
      this.$emit("phoneOrder", this.item);
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/styles/colors";
.host-item-menu-item {
  text-decoration: none;
  position: relative;
  .item-inner {
    box-shadow: 4px 4px 2px rgba($td-grey-darker, 0.1);
  }
  .special {
    background-color: $tdays-basic;
  }
  &.unavailable {
    .item-inner {
      background-color: $td-grey-light;
      color: $td-grey-basic;
    }
    .special {
      background-color: $td-grey-basic;
    }
  }
  .active-indicator {
    overflow: hidden;
  }
  .active-arrow {
    width: 12px;
    top: 50%;
    transform: translate(-100%, -50%);
    left: 0;
    overflow: hidden;
    height: 24px;
    &:after {
      content: "";
      width: 12px;
      position: absolute;
      left: 7px;
      top: 2px;
      background-color: #b5cdb4;
      aspect-ratio: 1;
      opacity: 0;
      transform: rotate(45deg) translate(100%, 0);
      transition: opacity 0.3s, transform 0.3s;
      transition-delay: 0.2s;
    }
  }
  .active-radius {
    aspect-ratio: 1;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0);
    width: calc(100% + 50px);
    background-color: #b5cdb4;
    opacity: 0;
  }
  &.router-link-exact-active {
    .active-arrow {
      &:after {
        opacity: 1;
        transform: rotate(45deg) translate(0, 0);
      }
    }
    .active-radius {
      animation: activeRadiusAnimation 0.3s normal forwards;
      @keyframes activeRadiusAnimation {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) scale(0);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) scale(1);
        }
      }
    }
  }
  .item-inner {
    background-color: $white;
    .feature .icon {
      min-width: 36px;
      text-align: center;
    }
  }
  .countdown {
    transform: scale(0.8);
    transform-origin: left center;
    padding-bottom: 15px;
  }
}

.discount-badge,
.regular-price,
.current-price {
  margin-right: 0.75em !important;
  &:after {
    position: absolute;
  }
}
.regular-price {
  &:after {
    content: "¹";
  }
}
.discount-badge {
  position: relative;
  &:after {
    right: -6px;
    content: "²";
    color: $td-grey-darker;
  }
  &.small-badge {
    right: 0;
    top: 0;
    @media (max-width: 440px) {
      top: -25px;
      &:after {
        color: $white;
      }
    }
  }
}
.current-price {
  &:after {
    content: "*";
  }
}
</style>
