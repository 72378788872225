<template lang="pug">
  .map-component.bg-td-grey-light
    #map-layer(:style="`${!isControllable ? 'pointer-events:none!important;':''}`" @click="handleClick")
    .host-popup.position-absolute.pb-1(v-if="highlightedHost && popupLocation" :style="`left:${popupLocation.x}px;top:${popupLocation.y}px;`")
      MapHostPreview(:host="highlightedHost" :jump-to="jumpToHost")
</template>
<script>
import config from "@/config/config";
import mapkit from "mapkit.js";
import { createHostAnnotation, createLocation, createRegion, triggerOnce } from "@/lib/helper/map";
import MapHostPreview from "@/views/components/hostPreview/MapHostPreview.vue";
import { clamp } from "@/lib/helper/math";
import { Hosts } from "@/graphql/Hosts.ts";
import { useMapStore } from "@/store/map-store";

export default {
  name: "MapComponent",
  components: { MapHostPreview },
  props: {
    hosts: Array,
    fitsHosts: {
      type: Boolean,
      default: true
    },
    showsHostPopup: {
      type: Boolean,
      default: true
    },
    location: {
      type: Object,
      validator(loc) {
        return (typeof loc.Latitude === "number" && typeof loc.Longitude === "number");
      },
      default: () => {
        return { Latitude: 51.19414980762646, Longitude: 8.312001898296874 };
      }
    },
    userLocation: {
      type: Object,
      validator(loc) {
        return (typeof loc.Latitude === "number" && typeof loc.Longitude === "number");
      }
    },
    zoom: Number
  },
  watch: {
    hosts() {
      this.annotateHosts();
    },
    zoom(level) {
      if (!this.map) return;
      this.map._impl.zoomLevel = level;
    },
    async location(location) {
      if (!this.map) return;
      await this.flyTo({
        center: {
          latitude: location.Latitude,
          longitude: location.Longitude
        }
      });
    }
  },
  setup() {
    const token = config.external.apple.mapkit;
    return { token };
  },
  data() {
    return {
      map: undefined,
      isInit: false,
      isControllable: true,
      highlightedHost: undefined,
      popupLocation: undefined
    };
  },
  methods: {
    hidePopup() {
      this.highlightedHost = undefined;
      this.popupLocation = undefined;
    },
    async flyTo(options) {
      this.hidePopup();
      return new Promise((resolve) => {
        triggerOnce(this.map, "region-change-end", resolve);
        const region = createRegion({
          Latitude: options.center.latitude,
          Longitude: options.center.longitude
        }, options.span);
        this.map.setRegionAnimated(region, true);
      });
    },
    async jumpToHost(host) {
      await this.flyTo({
        center: {
          latitude: host.Latitude,
          longitude: host.Longitude
        }
      });
    },
    async highlightHost(hostId) {
      const host = await this.$apollo.query({
        query: Hosts.Queries.MapHostDetails,
        variables: { id: hostId }
      }).then((response) => response?.data?.host);
      if (!host) return;
      this.highlightedHost = host;
    },
    async handleClick(e) {
      if (!this.showsHostPopup) return;
      this.hidePopup();
      if (!this.hosts?.length) return;
      if (!e?.target?.classList?.contains("mk-marker")) return;
      const el = e.target;
      const hostId = el.dataset.host;
      await this.highlightHost(hostId);
      const rect = el.getBoundingClientRect();
      const parentRect = this.$el.getBoundingClientRect();
      const x = clamp(rect.left - parentRect.left, 0) + 13.5;
      const y = clamp(rect.top - parentRect.top, 0);
      this.popupLocation = { x, y };
    },
    annotateHosts() {
      if (!this.map) return;
      this.map.removeAnnotations(this.map.annotations);
      if (!this.hosts?.length) return;
      //const hostIds = this.hosts.map((host) => host.id);
      this.hosts.forEach((host) => {
        const annotation = createHostAnnotation(host);
        this.map.addAnnotation(annotation);
      });
      if (this.fitsHosts) {
        this.map.showItems(this.map.annotations);
      }
    },
    destroyMap() {
      if (!this.map) return;
      this.map.destroy();
      this.map = undefined;
    },
    drawMap() {
      this.map = new mapkit.Map("map-layer", {
        center: createLocation(this.location),
        showsCompass: false,
        isRotationEnabled: false,
        showsMapTypeControl: false
      });
    },
    async initMap() {
      const mapStore = useMapStore();
      if (mapStore.isInitialized) return;
      return new Promise((resolve, reject) => {
        mapkit.addEventListener("error", (e) => {
          reject(e);
        });
        mapkit.init({
          language: "de",
          authorizationCallback: (done) => {
            mapStore.isInitialized = true;
            done(this.token);
            resolve();
          }
        });
      });
    }
  },
  async mounted() {
    try {
      await this.initMap();
      this.drawMap();
      this.annotateHosts();
      this.map.addEventListener("zoom-start", this.hidePopup);
      this.map.addEventListener("region-change-start", this.hidePopup);
      if (this.zoom) this.map._impl.zoomLevel = this.zoom;
      window.addEventListener("resize", this.hidePopup);
    } catch (e) {
      console.error(e);
    }
  },
  unmounted() {
    this.destroyMap();
    window.removeEventListener("resize", this.hidePopup);
  },
  destroyed() {
    this.destroyMap();
    window.removeEventListener("resize", this.hidePopup);
  }
};
</script>
<style lang="scss">
.map-component {

  .mk-marker {
    cursor: pointer;
    animation: markerAnimation 0.2s normal forwards;
  }

  @keyframes hostPopupAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes markerAnimation {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  .host-popup {
    width: 100%;
    aspect-ratio: 4 / 3;
    animation: hostPopupAnimation 0.4s normal forwards;
    transform: translate(-50%, -100%);
    max-width: 340px;

    .host-preview {
      margin-bottom: 0 !important;
    }

    &:after {
      content: "";
      background-color: #fff;
      width: 15px;
      height: 15px;
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -7.5px;
      transform: rotate(45deg);
    }
  }
}
</style>
<style scoped lang="scss">
.map-component {
  &, #map-layer, .loading-indicator {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
</style>
