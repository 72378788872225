import gql from "graphql-tag";

export const Categories = {
  Queries: {
    CategoryById: gql`
        query categoryById($id: ID) {
            categoryById(id: $id) {
                id
                Name1
                Name2
                WebTemplateId
                HtmlContent
                MetaDescription
                MetaKeywords
                Custom_MetaTitle
            }
        }
    `,
    HomeCategory: gql`
        query category($name: String, $lang: String) {
            category(name: $name, lang: $lang) {
                id
                WebTemplateId
                CustomAttributes {
                    Name1
                    Name2
                    HtmlContent
                    MetaDescription
                    MetaKeywords
                    Custom_MetaTitle
                }
            }
        }
    `,
    CategoryByUrl: gql`
        query Category($name: String) {
            category(name: $name) {
                id
                Name
                Url
                WebTemplateId
                CustomAttributes {
                    Name1
                    Name2
                    HtmlContent
                    MetaDescription
                    MetaKeywords
                    Custom_MetaTitle
                }
                CategorySliderItems {
                    id
                    RegularPrice
                    CurrentPrice
                    CustomAttributes {
                        Name2
                        Custom_Slider_Img_Vibrant_Color
                        Custom_Slider_Images
                        Custom_Product_Headline02
                    }
                    Host {
                        Url
                    }
                }
                RangeLimits {
                    Nights {
                        from
                        to
                    }
                    Price {
                        from
                        to
                    }
                    Persons {
                        from
                        to
                    }
                    Stars {
                        from
                        to
                    }
                }
            }
        }
    `,
    CategoryUrl: gql`
        query Query($categoryId: ID, $lang: String) {
            categoryUrl(id: $categoryId, lang: $lang)
        }
    `,
    NavbarCategories: gql`
        query navbarCategories {
            navbarCategories {
                Name
                Url
                Children {
                    Name
                    Url
                    Children {
                        Name
                        Url
                    }
                }
            }
        }
    `
  }
};
