<template lang="pug">
  .host-map.position-relative(style='height: 300px; width: 100%')
    MapComponent(v-if="hosts && hosts.length > 0 || location" :hosts="hosts" :location="location" :zoom="10" :shows-host-popup="false" :fits-hosts="hosts && hosts.length > 1")
</template>
<script>
import MapComponent from "@/views/components/map/MapComponent.vue";

export default {
  name: "HostMap",
  components: {
    MapComponent
  },
  props: {
    hosts: Array
  },
  data() {
    return {
      isInitialized: false
    };
  },
  computed: {
    location() {
      if (this.hosts?.length !== 1) return;
      const [host] = this.hosts;
      if (!host.Latitude || !host.Longitude) return;
      return { Latitude: parseFloat(host.Latitude), Longitude: parseFloat(host.Longitude) };
    }
  }
};
</script>
