import { AdventCalendar } from "@touridev/advent-calendar";
import AdventCalendarView from "@/views/static/adventCalendar/AdventCalendarView.vue";
import AdventCalendarWinners from "@/views/static/adventCalendar/AdventCalendarWinners.vue";
import TestView from "@/views/static/easter/TestView.vue";

export const useSeasonalRoutes = () => {
  return [
    {
      path: "/adventskalender",
      alias: ["/adventskalender-2023", "/xmas-2023"],
      component: AdventCalendarView,
      children: [
        {
          path: "/",
          name: "AdventCalendar",
          component: AdventCalendar,
          props: {
            winnerListingUrl: "/adventskalender/gewinner",
            privacyUrl: "/datenschutz",
            termsUrl: "/teilnahmebedingungen",
            highlights: [
              // liste der preise im oberen bereich der seite
              {
                imageUrl:
                  "https://s3-cloud.td-cdn.de/Images/xmas-2023/kocher.jpg",
                title: "5x EINE TRAUMHAFTE LUXUSREISE AB 4*",
                text: "Als Hauptpreis verlosen wir an Heiligabend in diesem Jahr 5 exquisite Deluxe-Reisen zu zweit in erlesenen First-Class-Hotels mit mindestens 4* teilweise 4*S und edlen 5* Häusern. Jede für sich eine einmalige Auszeit in purem Luxus."
              },
              {
                imageUrl:
                  "https://s3-cloud.td-cdn.de/Images/xmas-2023/mood1.jpg",
                title: "50 UNVERGESSLICHE REISEERLEBNISSE",
                text: "Traumhafte Tage an der See, aufregende Aktiv- & Wanderurlaube, einzigartige Wellnessauszeiten oder sehenswerte Erkundungstouren durch historische Städtchen. Wir verlosen täglich zwei dieser abwechslungsreichen Urlaubsangebote."
              },
              {
                imageUrl:
                  "https://s3-cloud.td-cdn.de/Images/xmas-2023/schlemmer.jpg",
                title: "2x EIN GENUSSVOLLER SCHLEMMERURLAUB ZUR AUSWAHL",
                text: "Gewinnen Sie einen von zwei „Schlemmerurlaub“ - Auswahlgutscheinen und lassen Sie sich in einem köstlichen Hotel Ihrer Wahl aus unserem ausgewählten Portfolio kulinarisch verzaubern."
              },
              {
                imageUrl:
                  "https://s3-cloud.td-cdn.de/Images/xmas-2023/wellness.jpg",
                title: "2x EIN ERHOLSAMER WELLNESSURLAUB ZUR AUSWAHL",
                text: "Wählen Sie selbst Ihre entspannte Auszeit zu zweit aus einem unserer erlesenen Wellnesshotels und lassen Sie sich in den besten Wellnessoasen verwöhnen."
              },
              {
                imageUrl:
                  "https://td-cloud.s3.eu-central-1.amazonaws.com/Images/xmas-2022/highlights/6.png",
                title: "250 touriDat WERTGUTSCHEINE",
                text: "Zusätzlich zu den großartigen Reisen aus unserem vielfältigen touriDat Portfolio verlosen wir jeden Tag noch mindestens zehn touriDat-Wertgutscheine im Wert von je 25€"
              }
            ]
          }
        },
        {
          path: "gewinner",
          component: AdventCalendarWinners,
          name: "AdventCalendarWinners"
        }
      ]
    },
    {
      path: "/easter",
      component: TestView
    }
  ];
};
